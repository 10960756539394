import Logo from "../assets/lifestyleprof_banner.jpg";
import TopMenu from "./TopMenu";
import Footer from "./Footer";

const Contact = () => {
    return (
        <div>
            <TopMenu back_image={Logo}/>
            <div className='footer-title'>
                <span>CONTACT US!</span>
            </div>
            <div className='contact-container'>
                <p>
                    Want to get in touch with us?
                    Feel free to send us a message using the details below.
                </p>
                <p>
                   admin@lifestyleprof.com
                </p>
            </div>
            <Footer/>
        </div>
    );
}

export default Contact